import dispatchEvent from "../utils/dispatch-event";
import { DrawerEvent } from "../enums/event";

type IType = {
  state: DrawerState.Open | DrawerState.Closed;
};

enum DrawerState {
  Open = "open",
  Closed = "closed",
}

class Drawer extends HTMLElement {
  drawer;
  constructor() {
    super();
    this.drawer = this.querySelector("[data-drawer]") as HTMLElement;

    this.registerEventListeners();
  }

  getDrawerName() {
    return this.drawer.dataset.drawer;
  }

  getState() {
    return this.drawer.dataset.state as IType["state"];
  }

  setState(state: IType["state"]) {
    return (this.drawer.dataset.state = state);
  }

  closeDrawer() {
    if (this.getState() === DrawerState.Closed) {
      return;
    }

    this.setState(DrawerState.Closed);

    dispatchEvent('page-overlay:deactivate', this.drawer);
  }

  openDrawer() {
    if (this.getState() === DrawerState.Open) {
      return;
    }

    this.setState(DrawerState.Open);

    dispatchEvent('page-overlay:activate', this.drawer);
  }

  registerEventListeners() {
    document.addEventListener(DrawerEvent.Toggle, (event) => {
      if (this.getDrawerName() !== (<CustomEvent>event).detail.drawerName) {
        this.closeDrawer();
        return;
      }

      if (this.getState() === DrawerState.Open) {
        this.closeDrawer();
        return;
      }

      this.openDrawer();
    });
  }
}

customElements.define("drawer-container", Drawer);

// const selectors = {
//   focusable:
//     'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]',
// };

// class Drawer {
//   drawer;
//   focusableElements;
//   openTriggers;
//   closeTriggers;
//   toggleTriggers;
//   name;
//   activeTrigger: HTMLElement;

//   constructor(element: HTMLElement) {
//     this.drawer = element;
//     this.focusableElements = [
//       ...this.drawer.querySelectorAll(selectors.focusable),
//     ] as HTMLElement[];
//     this.openTriggers = [
//       ...document.querySelectorAll(`[data-drawer-open]`),
//     ] as HTMLElement[];
//     this.closeTriggers = [
//       ...document.querySelectorAll(`[data-drawer-close]`),
//     ] as HTMLElement[];
//     this.toggleTriggers = [
//       ...document.querySelectorAll(`[data-drawer-toggle]`),
//     ] as HTMLElement[];

//     this.name = this.drawer.dataset.drawer;
//     this.activeTrigger = this.drawer;

//     this.registerEventListeners();
//     // this.hideFocusableElements();
//   }

//   get state() {
//     return this.drawer.dataset.state as IType["state"];
//   }

//   set state(state) {
//     this.drawer.dataset.state = state;
//   }

//   registerEventListeners() {
//     document.addEventListener(DrawerEnum.Open, (event) => {
//       if ((<CustomEvent>event).detail.drawer !== this.name) {
//         this.closeDrawer();
//         return;
//       }

//       this.activeTrigger = <HTMLElement>event.target;

//       if (this.state !== "open") {
//         this.openDrawer();
//       }
//     });

//     document.addEventListener(DrawerEnum.Close, (event) => {
//       if ((<CustomEvent>event).detail.drawer !== this.name) {
//         this.closeDrawer();
//         return;
//       }

//       this.activeTrigger = <HTMLElement>event.target;

//       if (this.state === "open") {
//         this.closeDrawer();
//       }
//     });

//     document.addEventListener(DrawerEnum.Toggle, (event) => {
//       if ((<CustomEvent>event).detail.drawer !== this.name) {
//         this.closeDrawer();
//         return;
//       }

//       this.activeTrigger = <HTMLElement>event.target;

//       if (this.state === "open") {
//         this.closeDrawer();
//         return;
//       }

//       this.openDrawer();
//     });

//     // document.addEventListener(EVENT.OVERLAY.DEACTIVATE, () =>
//     //   this.closeDrawer()
//     // );

//     this.openTriggers.forEach((openTrigger) => {
//       openTrigger.addEventListener("click", (event) => {
//         event.preventDefault();

//         dispatchEvent(DrawerEnum.Open, openTrigger, {
//           detail: {
//             drawer: openTrigger.dataset.drawerOpen,
//           },
//         });
//       });
//     });

//     this.closeTriggers.forEach((closeTrigger) => {
//       closeTrigger.addEventListener("click", (event) => {
//         event.preventDefault();

//         dispatchEvent(DrawerEnum.Close, closeTrigger, {
//           detail: {
//             drawer: closeTrigger.dataset.drawerClose,
//           },
//         });
//       });
//     });

//     this.toggleTriggers.forEach((toggleTrigger) => {
//       toggleTrigger.addEventListener("click", (event) => {
//         event.preventDefault();

//         dispatchEvent(DrawerEnum.Toggle, toggleTrigger, {
//           detail: {
//             drawer: toggleTrigger.dataset.drawerToggle,
//           },
//         });
//       });
//     });
//   }

//   closeDrawer() {
//     if (this.state === "closed") {
//       return;
//     }

//     this.state = "closed";
//     // this.hideFocusableElements();

//     if (this.activeTrigger) {
//       this.activeTrigger.dataset.state = "closed";
//     }

//     // dispatchEvent(EVENT.OVERLAY.DEACTIVATE, this.$drawer);

//     // Controlling focus
//     // if (this.focusElement) {
//     //   this.focusElement.focus();
//     // }

//     // if (this.tabTrapper) {
//     //   this.tabTrapper.close();
//     // }

//     // document.removeEventListener("keydown", (event) => {
//     //   if (event.key === KEY.ESCAPE) {
//     //     this.closeDrawer();
//     //   }
//     // });
//   }

//   openDrawer() {
//     // this.showFocusableElements();
//     this.state = "open";

//     if (this.activeTrigger) {
//       this.activeTrigger.dataset.state = "open";
//     }

//     // dispatchEvent(EVENT.OVERLAY.ACTIVATE, this.$drawer, {
//     //   detail: {
//     //     lockScroll: true,
//     //   },
//     // });

//     // Controlling focus
//     // this.focusElement = document.activeElement;
//     this.drawer.focus();

//     // this.tabTrapper = new TabTrapper(this.$drawer);

//     // document.addEventListener("keydown", (event) => {
//     //   if (event.key === KEY.ESCAPE) {
//     //     this.closeDrawer();
//     //   }
//     // });
//   }

//   // hideFocusableElements() {
//   //   this.$focusableElements.forEach(focusableElement => {
//   //     const el = focusableElement;
//   //     el.tabIndex = -1;
//   //   });
//   // }

//   // showFocusableElements() {
//   //   this.$focusableElements.forEach(focusableElement => {
//   //     const el = focusableElement;
//   //     el.tabIndex = 0;
//   //   });
//   // }
// }

// export default Drawer;
